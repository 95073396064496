import React, {useState} from 'react'
import axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import * as S from './styles/HomeStyles'
import ReactLoading from 'react-loading'

import TextField from '@material-ui/core/TextField';

import {     
    makeStyles,    
  } from '@material-ui/core/styles'



  const useStyles = makeStyles((theme) => ({    
    margin: {                      
        width: '50%',        
        borderRadius: 5,
        border: '1px solid black',
        height: '3.5rem',    
        backgroundColor: 'red',        
        boxShadow: '0 0 15px red',
        textAlign: 'center',
        fontSize: '1.5rem',
        zIndex: 5,
        
        
        [theme.breakpoints.up('sm')]: {
            width: '30%',
          },
    },    
    
    headText:{
        width: '20%',
        fontSize: '1rem',
        textShadow: '1px 1px 5px white',
        textalign: 'center',

        [theme.breakpoints.up('sm')]: {
            width: '30%',
            fontSize: '1.3rem',
            textShadow: '3px 1px 5px white',
          },

        transition: theme.transitions.create(['border-color', 'box-shadow'])
    }
  }));

  
  


const Home = () => {
    /* const [heros, setHeros] = useState([]) */
    const [heroi, setHeroi] = useState([])
    const [load, setLoad] = useState('none')
    const classes = useStyles()
    const [displayText, setDisplayText] = useState('flex')
             
    
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
          height: 15,
          borderRadius: 5,
        },
        colorPrimary: {
          backgroundColor: 'grey',
        },
        bar: {
          borderRadius: 5,
          backgroundColor: 'red',
        },
      }))(LinearProgress);
    

    async function fetchData(search) {
        setLoad('block')
         let response = await axios(`https://www.superheroapi.com/api.php/3992681667526682/search/${search}`)
        await setHeroi(response.data.results)        
        await setLoad('none')
        //await console.log(`Search - ${search} -- Response - ${heroi}`)            
    } 
    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

    const handleHeroi = (e) => {
        let searchHero = e.target.value
        setDisplayText('none')
        if(searchHero.length >= 3){
            fetchData(searchHero)  
        }

        if(searchHero.length === 0){
            fetchData(searchHero)  
            setDisplayText('flex')
        }
              
    }


    

    return(
        <S.container>
            
            <S.card>
                <S.divText style={{display: displayText}}>
                    <div>
                        <S.spanSearch >Encontre seu Super-Herói Favorito</S.spanSearch>
                    </div>

                    <div>
                        <S.spanSearch>Digite pelo menos 3 letras</S.spanSearch>
                    </div>
                </S.divText>

                <S.loader style={{display: load}}>
                    <ReactLoading type={'cylon'} color={'red'} height={367} width={175}  />

                </S.loader>

                <S.divSearch>
                                                                                                           
                    <TextField
                            className={classes.margin}
                            label="Search"
                            variant="outlined"
                        // id="mui-theme-provider-outlined-input"
                            onChange={handleHeroi}
                    />
                                        
                </S.divSearch>
                            
                {heroi ? heroi.map((hero) => 
                <>
                    <S.item>
                        <S.imgHero src={hero.image.url} />
                        
                        <S.listUl>
                            
                                <li>
                                    <h3>ID: {hero.id} - Nome: {hero.name}</h3>                    
                                </li>
                                <S.data>
                                    <h4>Powerstats</h4>
                                    <ul>
                                        <S.liItem >Intelligence: <br/>
                                        <BorderLinearProgress variant='determinate' value={hero.powerstats.intelligence}/> 
                                        <S.spanBar >{hero.powerstats.intelligence}%</S.spanBar>
                                        </S.liItem>

                                        <S.liItem>Strength: <br/> 
                                        <BorderLinearProgress variant='determinate' value={hero.powerstats.strength}/>
                                        <S.spanBar >{hero.powerstats.strength}%</S.spanBar>
                                        </S.liItem>

                                        <S.liItem>Speed: <br/> 
                                        <BorderLinearProgress variant='determinate' value={hero.powerstats.speed}/>   
                                        <S.spanBar >{hero.powerstats.speed}%</S.spanBar>                                 
                                        </S.liItem>

                                        <S.liItem>Durability: <br/> 
                                        <BorderLinearProgress variant='determinate' value={hero.powerstats.durability}/>
                                        <S.spanBar >{hero.powerstats.durability}%</S.spanBar>
                                        </S.liItem>

                                        <S.liItem>Combat: <br/> 
                                        <BorderLinearProgress variant='determinate' value={hero.powerstats.combat}/>
                                        <S.spanBar >{hero.powerstats.combat}%</S.spanBar>
                                        </S.liItem>

                                    </ul>
                                </S.data>
                                
                                <S.data>
                                    <h4>Appearance</h4>
                                    <ul>
                                        
                                        <li>Gender = {hero.appearance.gender}</li>                                    
                                        <li>Race = {hero.appearance.race}</li>
                                        <li>Height = {hero.appearance.height}</li>
                                        <li>Weight = {hero.appearance.weight}</li>                                    
                                    </ul>
                                </S.data>

                                <S.data>
                                    <h4>Work</h4>
                                    <ul>
                                        
                                        <li>Occupation = {hero.work.occupation}</li>                                    
                                        <li>Base = {hero.work.base}</li>                                                                        
                                    </ul>
                                </S.data>
                            
                        </S.listUl>
                    </S.item>
                </>
                ) : ''}  
                         
            </S.card>                            
        
        </S.container>
    )
}

export default Home